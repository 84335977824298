import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TabsComponent } from './tabs/tabs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ReferanceComponent } from './referance/referance.component';
import { ReviewFilesComponent } from './review-files/review-files.component';
import { InitiateDownloadComponent } from './initiate-download/initiate-download.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FlagDragNDropDirective } from './directive/flag-drag-ndrop.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { NgxSpinnerModule} from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReCaptchaComponent } from './re-captcha/re-captcha.component';
import { FooterComponent } from './footer/footer.component';
import { EmailAuthComponent } from './email-auth/email-auth.component';
import { HeaderComponent } from './header/header.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TabsComponent,
    FileUploadComponent,
    ReferanceComponent,
    ReviewFilesComponent,
    InitiateDownloadComponent,
    ConfirmationComponent,
    FlagDragNDropDirective,
    FileSizePipe,
    ReCaptchaComponent,
    FooterComponent,
    EmailAuthComponent,
    HeaderComponent,
    UploadFilesComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbTooltipModule 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [FileSizePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
