import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { AuthService } from './service/auth-service';
import { NotificationService } from './service/notification-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'FileCapture';
  constructor(private router: Router, private authService: AuthService, private notificationService: NotificationService,) {
        
  }

  ngOnInit() {
  }
}
