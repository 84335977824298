import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileServiceService } from '../service/file-service.service';
import { FileCaptureService } from 'app/service/file-capture.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-referance',
  templateUrl: './referance.component.html',
  styleUrls: ['./referance.component.css']
})
export class ReferanceComponent implements OnInit {
  authCode = '';
  referenceForm: FormGroup = new FormGroup({
    businessName: new FormControl('', [Validators.required, Validators.maxLength(60)]),
    contactName: new FormControl('', [Validators.required, Validators.maxLength(60)]),
    contactPhone: new FormControl(''),
    contactEmail: new FormControl('', [Validators.required, Validators.maxLength(60), Validators.email, this.customEmailValidator  ])
  });

  constructor(private route: Router, public service: FileServiceService,  public fileService: FileCaptureService) {
    this.service.pageIndex = 1;
   }

  ngOnInit() {
    this.referenceForm.get('businessName')?.setValue(this.service.getCache("businessName"));
    this.referenceForm.get('contactName')?.setValue(this.service.getCache("contactName"));
    this.referenceForm.get('contactPhone')?.setValue(this.service.getCache("contactPhone"));
    this.referenceForm.get('contactEmail')?.setValue(this.service.getCache("contactEmail"));    
    this.service.referenceFileDetails.referenceId = this.service.getCache("referenceId");        
  }
  customEmailValidator(control: FormControl): { [key: string]: boolean } | null {
    const email = control.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const domainRegex = /\.(com|in|us|cz)$/;

    if (email && (!emailRegex.test(email) || !domainRegex.test(email))) {
      return { invalidEmailFormat: true };
    }
    return null;
  }

  next() 
  {
    if (this.referenceForm.invalid) {
      return;
    }

    this.service.referenceFileDetails.businessName = this.referenceForm.get('businessName')?.value;
    this.service.referenceFileDetails.contactName = this.referenceForm.get('contactName')?.value;
    this.service.referenceFileDetails.contactPhone = this.referenceForm.get('contactPhone')?.value;
    this.service.referenceFileDetails.contactEmail = this.referenceForm.get('contactEmail')?.value;
    
    this.service.setCacheForitems(
      [{id:"businessName", value:this.service.referenceFileDetails.businessName},
      {id:"contactName", value:this.service.referenceFileDetails.contactName},
      {id:"contactPhone", value:this.service.referenceFileDetails.contactPhone},
      {id:"contactEmail", value:this.service.referenceFileDetails.contactEmail},
      {id:"referenceId", value:this.service.getCache('referenceId')}]
  );
    this.route.navigate(['email-auth']);
  }

  close() {
    this.service.referenceFileDetails.referenceId = "";
    this.service.clearCache();
    this.route.navigate(['']);
  }

  generateUniqueCode() {
    const contactEmail = this.referenceForm.get('contactEmail')?.value;
    this.fileService.generateCode(contactEmail, this.service.referenceFileDetails.referenceId).subscribe(authCode => {
      this.authCode = authCode;
    });
  }
}
