import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http'; 
import { Observable, delay, of, switchMap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FileCaptureService {
  
  baseUrl = environment.baseUri;
  // baseUrl = environment.baseUri; Use this for local development 
  constructor(private http: HttpClient) { }

  public getReferenceId(referenceId: string| null): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + "FileCapture/ValidateReferal?referenceid=" + referenceId);
  }

  public submitDocument(data: any| null): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + "FileCapture/Submit?Submit" ,data);
  }

  public addAttachmentToDB(data: any| null): Observable<number> {
    return this.http.post<number>(this.baseUrl + "FileCapture/AddAttachmentToDB" ,data);
  }
  public commitFile(attachmentKey: any| null, fileName, totalChunks): Observable<number> {
    return this.http.get<number>(this.baseUrl + "FileCapture/CommitFile?attachmentKey="+attachmentKey+"&fileName="+fileName+"&totalChunks="+totalChunks);
  }
  public ReviewAndScanAttachmnents(data: any| null):Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + "FileCapture/ReviewAndScanAttachmnents" ,data);
  }

  public GetAttachmentStatus(data: any| null):Observable<any> {
    return this.http.post<any>(this.baseUrl + "FileCapture/GetAttachmentStatus" ,data);
  }
  public pollStatus(interval: number, data: any| null): Observable<any> { 
    return this.GetAttachmentStatus(data).pipe( switchMap(status => { 
        if (status.filter(x => x.VirusScanStatus == null || x.VirusScanStatus == 0).length == 0) { 
          return of(status); 
        } 
        else { 
          return of(status).pipe( delay(interval), switchMap(() => this.pollStatus(interval, data)) ); 
        } 
      })); 
  }
  public uploadAttachment(data: any| null, sourceAppKey, sourceKey, activityKey, attachmentKey, referenceId): Observable<number> {
    return this.http.post<number>(this.baseUrl + "FileCapture/UploadAttachment?sourceAppKey="+sourceAppKey+"&sourceKey="+sourceKey+"&activityKey="+activityKey+"&attachmentKey="+attachmentKey+"&referenceId"+referenceId,data);
  }

  public generateCode(contactEmail: string | null, refid: string | null) {
    return this.http.post<string>(this.baseUrl + "EmailAuth/GenerateCode?contactEmail=" + contactEmail + "&refid=" + refid, null);
  }
  public validateCode(data: any| null): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + "EmailAuth/ValidateCode", data);
  }

  public addFilesToDB(data: any| null): Observable<HttpEvent<number>> {
    return this.http.post<number>(this.baseUrl + "FileCapture/addFilesToDB" ,data,  {
      reportProgress: true,
      observe: 'events'
    });
  }

}
