<app-header></app-header>

<div class="col-md-3 offset-md-1">
  <div class="row">
      <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
      <span class="ref-id-no">{{service.referenceFileDetails.referenceId}}</span>
  </div>
</div>

<div class="col-sm-10 col-md-10 col-lg-10 offset-md-1">
  <form [formGroup]="emailForm" (ngSubmit)="next()">
    <div class="form-group mt-2 mb-2">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <label class="text-white">An Email Verification Code has been emailed to:</label> &nbsp;
        <span class="text-white-email font-weight-bold">{{ emailForm.get('validateEmail')?.value }}</span>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <label class="text-white">Enter the Code Here:</label>
        <span>
          <input type="text" class="form-control" id="email-code" (keypress)="enableSubmit()" (click)="enableSubmit()" placeholder="Email Code" formControlName="emailCode" name="emailCode" maxlength="6">
        </span>
      </div>
      
    </div>
  </form>
</div>


<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1 emailfooter">
  <div class="col container--flex">
    <button type="button" class="btn btn-warn me-2"  id="dashboardButton" (click)="close()">Close</button>
    <div [ngbTooltip]="!emailForm.get('emailCode')?.valid ? 'Please enter a valid 6-digit verification code' : null" container="body" [tooltipClass]="'tooltip-disabled'">
      <button class="btn btn-success me-2" type="submit" [disabled]="!emailForm.get('emailCode')?.valid" (click)="validateCode()">Click Here To Verify The Code Entered</button>
    </div>
    <span class="spinner-border text-light me-2" role="status" [style.display]="isVisible ? 'block' : 'none'"></span>
    <span class="text-warning me-2" [style.display]="invalid ? 'block' : 'none'">Entered Code is invalid</span> 
    <button class="btn btn-success me-2" type="submit" [disabled]="timerMinutes > 0 || timerSeconds > 0" (click)="resetTimer()">ReSend Code</button>
    <span class="text-white">{{ timerMinutes }}:{{ timerSeconds | number: '2.0-0' }}</span>
  </div>
</div>

<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1">       
  <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>         
  <span>For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email</span>
  <div class="row">
    <div class="text-white-s ml-5">
        <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
        <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
        <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
    </div>
  </div>
</div>
