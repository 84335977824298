import { Injectable } from "@angular/core";
import { environment } from 'environments/environment';
declare var $: any; // Declare jQuery
@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor() {        
    }
    queue = [];
    parameters = [];
    add(item){
        this.queue.push(item);
        var time = 20000;
        if (typeof item.time !== "undefined") {
            time = item.time;
        }
        var queueList = this.queue;
        setTimeout(function () {
            $('.alerts .alert').eq(0)?.remove();
            //document.querySelectorAll(".alerts .alert")[0]?.remove();
            queueList.shift();
        }, time);
    };

    pop() {
        return this.queue.pop();
    };
}
