<app-header></app-header>

<div class="col-md-3 offset-md-1">
    <div class="row">
        <span class="text-white ref">Reference ID</span>
    </div>
    <div class="row">
        <span class="ref-id-no">{{service.referenceFileDetails.referenceId}}</span>
    </div>
</div>

<div class="row">
    <div class="col-md-2 offset-md-1 mt-3">
        <a class="btn btn-info-current w-40" *ngIf="service.pageIndex>0" (click)="service.navigateTo('reference')">Contact Information</a>
    </div>
    <div class="col-md-2 mt-5">
        <a class="btn btn-info w-40" *ngIf="service.pageIndex>2" (click)="service.navigateTo('file-upload')">File Upload</a>
    </div>
    <div class="col-md-2 mt-5">
        <a class="btn btn-info w-40" *ngIf="service.pageIndex>3" (click)="service.navigateTo('review-files')">Review Files</a>
    </div>
    <div class="col-md-2 mt-5">
        <a class="btn btn-info w-40" *ngIf="service.pageIndex>4" (click)="service.navigateTo('initiate-download')">Initiate Download</a>
    </div>
</div>

<div class="row">
    <div class="col-md-8 offset-md-1 mt-2 mb-3">
        <span class="file-download-err">Please provide contact information for any questions or concerns with the file download.</span>
    </div>
</div>

<form [formGroup]="referenceForm" (ngSubmit)="next()" class="referenceheader">
    <div class="col-md-3 offset-md-1">
        <div class="form-group mt-2 mb-2">
            <label class="text-white">Business Name</label> <span class="text-white-sub">(Required)</span>
            <input type="text" class="form-control" id="business" placeholder="Business Name" formControlName="businessName" name="businessName" maxlength="60">
        </div>
        <div class="form-group mt-2 mb-2">
            <label class="text-white">Contact Name</label> <span class="text-white-sub">(Required)</span>
            <input type="text" class="form-control" id="contact-name" placeholder="Contact Name" formControlName="contactName" name="contactName" maxlength="60">
        </div>
        <div class="form-group mb-2">
            <label class="text-white">Contact Phone</label> <span class="text-white-sub">(Required)</span>
            <input type="text" class="form-control" id="contact-phone" placeholder="999-999-9999" formControlName="contactPhone" name="contactPhone">
        </div>
        <div class="form-group mt-2 mb-2">
            <label class="text-white">Contact Email</label> <span class="text-white-sub">(Required)</span>
            <input type="text" class="form-control" id="contact-email" placeholder="Email ID" formControlName="contactEmail" name="contactEmail" maxlength="60">
                <div *ngIf="referenceForm.get('contactEmail')?.errors?.['invalidEmailDomain'] && referenceForm.get('contactEmail')?.touched" class="text-danger">
                    Please enter a valid email ending with .com, .in, .us, .cz, .org, .net
                </div>
          </div>
    </div> 

    <div class="col-md-6 offset-md-1">
        <div  class="button-group">
            <button type="button" class="btn-clear" id="dashboardButton" (click)="close()">Close</button>
            <button type="submit" class="btn-enter" id="dashboardButton" [disabled]="referenceForm.invalid" (click)="generateUniqueCode()"  [ngbTooltip]="referenceForm.invalid ? 'Please fill out the required fields to proceed' : ''"
            [tooltipClass]="'tooltip-disabled'" >Click Here To Proceed To Email Verification</button>    
        </div> 
    </div>
</form>
<!-- Footer  -->
<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1">
    <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>        
    <span>For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email</span>
    <div class="row">
        <div class="text-white-s ml-5">
            <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
            <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
            <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
        </div>
    </div>
</div>
