<app-header></app-header>
<div class="alerts" *ngIf="notification?.queue?.length > 0">
  <div [ngClass]="{'alert alert-{{m.type}} alert-dismissable fade in pull-right' : (m.type != 'info'), 'alert alert-info alert-dismissable fade in pull-right': (m.type == 'info')}"
   *ngFor="let m of notification.queue">
      <button type="button" class="close" data-dismiss="alert" style="font-size: .9em;">
          x
      </button> &nbsp;
      <label>{{ m.title }}</label>
      <div>{{ m.body }}</div>
  </div>
</div>

<div class="col-md-3 offset-md-1">
  <div class="row">
    <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
    <span class="ref-id-no">{{ service.referenceFileDetails.referenceId }}</span>
  </div>
</div>

<div class="col-md-12 col-lg-12 col-sm-12">
  <form (ngSubmit)="next()">
    <!-- Navigation Links -->
    <div class="row justify-content-start link-container">
      <div class="col-md-auto offset-md-1 mt-3 ">
        <a class="btn btn-info custom-button" *ngIf="service.pageIndex > 0" (click)="service.navigateTo('reference')">
          Contact Information
        </a>
      </div>
      <div class="col-md-auto mt-3">
        <a class="btn btn-info-current custom-button" *ngIf="service.pageIndex > 2" (click)="service.navigateTo('file-upload')">
          File Upload
        </a>
      </div>
      <div class="col-md-auto">
        <a class="btn btn-info custom-button" *ngIf="service.pageIndex > 3" (click)="service.navigateTo('review-files')">
          Review Files
        </a>
      </div>
      <div class="col-md-auto">
        <a class="btn btn-info custom-button" *ngIf="service.pageIndex > 4" (click)="service.navigateTo('initiate-download')">
          Initiate Download
        </a>
      </div>
    </div>

    <!-- Error Message -->
    <div class="row" *ngIf="showError">
      <div class="col-md-6 offset-md-1 mt-5">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="showError = !showError"></button>
          <strong>Error </strong>{{ errorMsg }}
        </div>
      </div>
    </div>

    <!-- File Upload Section -->
    <div class="row align-items-center">
      <div class="col col-md-4 file-uploader-div fileuploadleft dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
        <input class="choosefiles" type="file" name="file" id="file" (change)="onFileChange($event.target)" [accept]="acceptedFiles" multiple>
        <label for="file" class="choosefiles">
          <span class="textLink">Choose a File or Drag Files to Upload</span>
        </label>
      </div>
      <div class="col col-md-4" *ngIf="files.length > 0">
        <div class="overflow-auto">
          <table class="table text-white">
            <thead class="bg-dark">
              <tr>
                <th scope="col">Files</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of files">
                <td class="text-white-sub">{{ item.fileName ?? item.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="col-md-6 offset-md-1">
      <div class="button-group">
        <button type="button" class="btn-clear" id="dashboardButton" (click)="back()">Back</button>
        <button type="submit" class="btn-enter" id="dashboardButton" [disabled]="files.length === 0 || isDisabled"  [ngbTooltip]="(files.length === 0 || isDisabled) ? 'You must upload a file to proceed.' : null" container="body"> Click to Advance </button>        <div *ngIf="isDisabled" class="d-flex align-items-center gap-2">
          <div class="spinner-border text-light" role="status"></div>
          <span class="text-warning">Files are being uploaded</span>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Upload Instructions -->
<div class="row  mt-3">
  <div class="col-md-6 offset-md-1">
    <span class="text-white">Upload Instructions:</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">1. Click in the box to upload files or drag and drop files directly into the box.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">2. Ensure that you upload the requested information as directed in the request letter(i.e., separate files for each Patient ).</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">3. Acceptable file types include: doc, docx, dcm, pdf, xls, xlsx, jpeg, jpg, bmp, png, tif, tiff, wav, mp3, txt, and text/plain.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">4. If the file exceeds 1.5GB, please split the files into multiple files.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">Once all files have been uploaded, click Next to Review Files. Files are not submitted until you reach the final submission step.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">5. Once all files have been uploaded, click the ‘Click to Advance’ button. Files are not submitted until you reach the final submission step.</span>
  </div>
</div>

<!-- Footer -->
<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1 mt-3">
  <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>        
  <span> For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email.</span>
  <div class="row">
    <div class="text-white-s ml-5">
      <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> |
      <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> |
      <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a>
    </div>
  </div>
</div>
