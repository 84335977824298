<app-header></app-header> 

<div class="col-md-3 offset-md-1 ">
    <div class="row">
      <span class="text-white ref">Reference ID</span>
    </div>
    <div class="row">
      <span class="ref-id-no">{{ service.referenceFileDetails.referenceId }}</span>
    </div>
  </div>

<form (ngSubmit)="next()" class="referenceheader">
    <div class="row link-container">
        <div class="col-md-auto offset-md-1 mt-3">         
            <a class="btn btn-info custom-button" *ngIf="service.pageIndex>0" (click)="service.navigateTo('reference')">Contact Information</a>
        </div>
        <div class="col-md-auto mt-3">
            <a class="btn btn-info custom-button" *ngIf="service.pageIndex>2" (click)="service.navigateTo('file-upload')">File Upload</a>
        </div>
        <div class="col-md-auto mt-3">
            <a class="btn btn-info-current custom-button" *ngIf="service.pageIndex>3" (click)="service.navigateTo('review-files')">Review Files</a>
        </div>
        <div class="col-md-auto mt-3">
            <a class="btn btn-info custom-button" *ngIf="service.pageIndex>4" (click)="service.navigateTo('initiate-download')">Initiate Download</a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 offset-md-1 mt-3">
            <div class="overflow-auto">
                <table class="table text-white">
                    <thead class="bg-dark">
                        <tr>
                            <th class="col-md-4" scope="col"></th>
                            <th class="col-md-3" scope="col">File Name</th>
                            <th class="col-md-3" scope="col">File Size</th>
                            <th class="col-md-2" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of files; let i=index;">
                            <th scope="row">
                                    <button type="button" class="btn-enter" id="dashboardButton" (click)="removeFile(i)">Remove File</button>
                            </th>
                            <td class="col-md-3 text-white-sub">{{item.fileName}}</td>
                            <td class="col-md-3 text-white-sub">{{item.fileSize | fileSize}}</td>
                            <td class="col-md-3 text-white-sub">
                                <div [attr.id]="item.attachmentKey + '_0'" [style.display]="item.isVisible == (item.attachmentKey + '_0') ? 'block' : 'none'" class="text-warning"><i class="fa fa-spinner fa-spin"></i></div>
                                <div [attr.id]="item.attachmentKey + '_1'" [style.display]="item.isVisible == (item.attachmentKey + '_1') ? 'block' : 'none'" class="text-success"><i class="fa fa-check"></i></div>
                                <div [attr.id]="item.attachmentKey + '_99'" [style.display]="item.isVisible == (item.attachmentKey + '_99') ? 'block' : 'none'" class="text-danger"><i class="fa fa-xmark"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table class="table text-white">
                <tbody>
                    <tr>
                        <th scope="row">Total</th>
                        <td>{{totalFilesSize | fileSize}}</td>
                        <td>
                            <button type="button" class="btn-enter" id="dashboardButton" (click)="addNewFile()">Add Additional Files</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
           <div class="col-md-5 mt-3">
            <span class="text-white">Upload Review:</span>
            <div>
                <span class="text-white-sub">❑ Confirm all requested files have been uploaded with individual file names.</span>
            </div>
            <div>
                <span class="text-white-sub">❑ To Add Files not previously uploaded, select Add Additional Files.</span>
            </div>
            <div>
                <span class="text-white-sub">❑ To Remove individual files, select Remove File.</span>
            </div>
            <div>
                <span class="text-white-sub">❑ Once you have confirmed that all files are selected for upload, click the 'click Next to Initiate the Download' button.</span>
            </div>
            <div class="mt-5">
                <span class="text-white">Files are not submitted until you reach the final submission step.</span>
            </div>
        </div>
    </div>



    <div class="col-md-6 offset-md-1">
        <div class="button-group">
          <button type="button" class="btn-clear" id="dashboardButton" (click)="back()">Go Back</button>
          <button type="submit" class="btn-enter" id="dashboardButton" [disabled]="files.length === 0">Click to Initiate the Download</button>
        </div>
      </div>
</form>

<!-- Footer -->
<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1">    
    <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>            
    <span>For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email</span>
<div class="row">
    <div class="text-white-s ml-5">
        <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
        <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
        <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
    </div>
</div>
</div>